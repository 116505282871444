import { Box, Typography } from "@mui/material";
import Loader from "../../../components/Loader";

function Title({ data, lang }) {
  if (!data) {
    return <Loader />;
  }

  return (
    <Box
      sx={{
        backgroundImage: `url(${data?.map((item) => item.image)})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        maxWidth: "100%",
        pointerEvents: "none",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "4em",
          padding: "5em",
          alignItems: lang === "en" ? "start" : "end",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "6em",
            backgroundImage: "linear-gradient(to bottom, #211C52, #D39ED5)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          {data?.map((item, index) => (
            <Typography
              key={index}
              variant="h1"
              sx={{
                fontSize: {
                  xs: "38px",
                  sm: "42px",
                  md: "48px",
                  lg: "58px",
                  xl: "64px",
                },
                fontFamily: "CairoBold",
                textTransform: "uppercase",
                whiteSpace: "pre-wrap",
                lineHeight: "2em",
                direction: lang === "en" ? "ltr" : "rtl",
              }}
            >
              {item.translations[0].translatedValue.split(" ").join("\n")}
            </Typography>
          ))}
        </Box>
        <Typography
          variant="h3"
          sx={{
            fontSize: {
              xs: "20px",
              sm: "24px",
              md: "32px",
              lg: "36px",
            },
            color: "white",
            direction: lang === "en" ? "ltr" : "rtl",
          }}
        >
          {data?.map((item) => item.translations[1].translatedValue)}
        </Typography>
      </Box>
    </Box>
  );
}

export default Title;
