import { Box, Button, Grid, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import Loader from "../../../components/Loader";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useEffect, useState } from "react";

export default function Services({ lang }) {
  const { t } = useTranslation();
  const selectedLanguage = useLocation().pathname.split("/")[1];
  const [data, setData] = useState(null);

  useEffect(() => {
    const language = window.location.pathname.split("/")[1] || "en";

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/services?key=!isDeleted&language=${language}`
      )
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error retrieving data:", error);
      });
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "100%",
        gap: "2em",
        padding: "3em",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          gap: { xs: "2em", sm: "0" },
          direction: lang === "en" ? "ltr" : "rtl",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontFamily: "CairoBold",
            fontSize: { xs: "32px", sm: "36px", md: "42px", lg: "48px" },
            textAlign: { xs: "center", sm: "start" },
          }}
        >
          {t("our_services")}
        </Typography>
      </Box>
      {data ? (
        <Grid container spacing={2}>
          {data?.services?.slice(0, 4).map(
            (service, index) =>
              service && (
                <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1em",
                    }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        borderRadius: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        overflow: "hidden",
                      }}
                    >
                      {service.image && (
                        <>
                          <Box
                            sx={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              zIndex: -1,
                              backgroundImage: `url(${service.image})`,
                              backgroundSize: "cover",
                              filter: "blur(10px)",
                              borderRadius: "15px",
                            }}
                          />
                          <img
                            src={service.image}
                            alt=""
                            style={{
                              height: "300px",
                              objectFit: "contain",
                            }}
                          />
                        </>
                      )}
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        component={Link}
                        to={`/${selectedLanguage}/services/${service.id}`}
                        variant="caption"
                        sx={{ margin: "0 auto", fontSize: "24px" }}
                      >
                        {
                          service.translations.find(
                            (item) => item.fieldName === "title"
                          )?.translatedValue
                        }
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              )
          )}
        </Grid>
      ) : (
        <Loader />
      )}
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Button
          component={Link}
          to={`/${selectedLanguage}/services`}
          sx={{
            width: "fit-content",
            border: "1px solid #706F71",
            color: "#706F71",
            fontSize: { xs: "16px", md: "20px" },
            "&: hover": {
              border: "1px solid #211C52",
              color: "#211C52",
            },
          }}
        >
          {t("see all")}
        </Button>
      </Box>
    </Box>
  );
}
