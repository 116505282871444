import {
  Box,
  Typography,
  styled,
  Switch,
  ImageList,
  ImageListItem,
  Grid,
} from "@mui/material";
import { useState } from "react";
import YouTube from "react-youtube";
import MyAPI from "../../components/API";
import Loader from "../../components/Loader";
import { useTranslation } from "react-i18next";

const MaterialUISwitch = styled(Switch)(({ checked }) => ({
  width: 62,
  height: 35,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 0,
    padding: 0,
    transform: "translateX(6px)",
    top: 7,
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(34px)",
      "& .MuiSwitch-thumb:before": {},
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#FFFFFF",
        border: "1px solid #211C52",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#211C52",
    backgroundImage: `url(/images/arrow.svg)`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 22,
    height: 22,
    transform: checked ? "rotate(0)" : "rotate(180deg)",
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    border: "1px solid #211C52",
  },
}));

function getYouTubeVideoId(url) {
  const videoIdPattern = /[?&]v=([^&]+)/;
  const match = url.match(videoIdPattern);

  if (match && match[1]) {
    return match[1];
  }

  return null;
}

export default function WhatIsNew() {
  const [switchChecked, setSwitchChecked] = useState(true);
  const [data, setData] = useState(null);
  const [isLoadingImages, setIsLoadingImages] = useState(true);
  const [isLoadingVideos, setIsLoadingVideos] = useState(true);
  const { t } = useTranslation();

  return (
    <>
      <MyAPI endpoint={"whatisnew"} setData={setData} />
      <Box
        sx={{
          maxWidth: "100%",
          bgcolor: "#211C52",
          padding: "1em",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Box sx={{ width: "8vw" }}>
            <img
              src="/images/whatIsNewBlur.png"
              alt=""
              style={{ width: "100%", height: "auto" }}
            />
          </Box>
        </Box>
        <Box sx={{ flex: 1 }}>
          <img
            src={data?.staticPages?.map((item) => item.image)}
            alt=""
            style={{ width: "100%", height: "auto" }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
            flex: 1,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Typography
              sx={{
                color: "#FFFFFF",
                whiteSpace: "nowrap",
                fontSize: { xs: "20px", sm: "38px" },
                fontFamily: "CairoBold",
              }}
            >
              {t("what’s new")}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          padding: "4em",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1em",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "1em",
            alignItems: "center",
            "> .MuiTypography-root": {
              fontFamily: "CairoBold",
            },
          }}
        >
          <Typography
            variant="h6"
            sx={{
              transition: "0.3s all",
              color: switchChecked ? "#000000" : "#A34D97",
            }}
          >
            {t("videos")}
          </Typography>
          <MaterialUISwitch
            defaultChecked
            checked={switchChecked}
            onChange={(e) => setSwitchChecked(e.target.checked)}
          />
          <Typography
            variant="h6"
            sx={{
              transition: "0.3s all",
              color: switchChecked ? "#A34D97" : "#000000",
            }}
          >
            {t("gallery")}
          </Typography>
        </Box>
        {switchChecked && data && (
          <ImageList variant="masonry" cols={3} gap={8}>
            {data.gallery.photos.map((photo, index) => (
              <ImageListItem key={index}>
                {isLoadingImages && <Loader />}
                <img
                  src={photo.url}
                  alt={`Gallery ${index}`}
                  style={{
                    display: isLoadingImages ? "none" : "block",
                  }}
                  onLoad={() => setIsLoadingImages(false)}
                />
              </ImageListItem>
            ))}
          </ImageList>
        )}
        {!switchChecked && data && (
          <Grid container spacing={3}>
            {data.gallery.videos.map((video, index) => {
              const isYouTubeVideo = video.url.includes("youtube.com");

              return (
                <Grid item xs={12} md={4} key={index}>
                  {isLoadingVideos && <Loader />}
                  {isYouTubeVideo ? (
                    <YouTube
                      videoId={getYouTubeVideoId(video.url)}
                      opts={{
                        width: "100%",
                      }}
                      style={{
                        display: isLoadingVideos ? "none" : "block",
                      }}
                      onReady={() => setIsLoadingVideos(false)}
                    />
                  ) : (
                    <video
                      src={video.url}
                      controls
                      style={{
                        width: "100%",
                        display: isLoadingVideos ? "none" : "block",
                      }}
                      onLoad={() => setIsLoadingVideos(false)}
                    />
                  )}
                </Grid>
              );
            })}
          </Grid>
        )}
      </Box>
    </>
  );
}
