import {
  Box,
  Button,
  ImageList,
  ImageListItem,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import Loader from "../../../components/Loader";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Photobooth360() {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const { lang } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/services/${id}`, {
        params: {
          language: lang,
        },
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error retrieving data:", error);
      });
  }, []);

  return (
    <>
      {data ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1em",
              justifyContent: "center",
              alignItems: "center",
              p: "2em",
            }}
          >
            <Typography
              variant="h2"
              sx={{
                color: "#211C52",
                textAlign: "center",
                fontFamily: "CairoBold",
              }}
            >
              {
                data?.service?.translations.find(
                  (item) => item.fieldName === "title"
                )?.translatedValue
              }
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ color: "#211C52", width: "70%", textAlign: "center" }}
            >
              {
                data?.service?.translations.find(
                  (item) => item.fieldName === "description"
                )?.translatedValue
              }
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              maxWidth: "100%",
              flexDirection: {
                xs: "column",
                sm: "row",
              },
              flexWrap: "wrap",
            }}
          >
            <Box sx={{ display: "flex", flex: 1, width: "100%" }}>
              <img
                src={data?.service?.image ? data.service.image : <Loader />}
                alt=""
                style={{
                  maxHeight: "60vh",
                  maxWidth: "100%",
                  width: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              p: { xs: "1em", sm: "2em", lg: "4em" },
              display: "flex",
              flexDirection: "column",
              gap: "2em",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                color: "#211C52",
                fontFamily: "CairoBold",
                direction: lang === "en" ? "ltr" : "rtl",
              }}
            >
              {
                data?.service?.translations.find(
                  (item) => item.fieldName === "featuresTitle"
                )?.translatedValue
              }
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                backgroundImage: {
                  xs: "none",
                  sm: `url(${
                    data?.service?.featuresImage ? (
                      data.service.featuresImage
                    ) : (
                      <Loader />
                    )
                  })`,
                },
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: lang === "en" ? "right" : "left",
                flexDirection: { xs: "column", sm: "row" },
                gap: "2em",
                direction: lang === "en" ? "ltr" : "rtl",
              }}
            >
              <Box
                sx={{
                  display: { xs: "flex", sm: "none" },
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <img
                  src={
                    data?.service?.featuresImage ? (
                      data.service.featuresImage
                    ) : (
                      <Loader />
                    )
                  }
                  alt=""
                  style={{ width: "50%" }}
                />
              </Box>

              <List
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2em",
                  direction: lang === "en" ? "ltr" : "rtl",
                }}
              >
                {data.service.translations
                  .filter((item) => item.fieldName === "features")
                  .map((item, index) => (
                    <ListItem
                      disablePadding
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1em",
                      }}
                    >
                      <Box
                        sx={{
                          width: "10px",
                          height: "10px",
                          borderRadius: "50%",
                          bgcolor: "#211C52",
                        }}
                      />
                      <Typography variant="h5" sx={{ fontFamily: "CairoBold" }}>
                        {item?.translatedValue ? (
                          item?.translatedValue
                        ) : (
                          <Loader />
                        )}
                      </Typography>
                    </ListItem>
                  ))}
              </List>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              p: { xs: "1em", sm: "2em", lg: "4em" },
            }}
          >
            <Typography
              variant="h4"
              sx={{ color: "#211C52", fontFamily: "CairoBold" }}
            >
              {t("activity")}
            </Typography>
            <ImageList variant="masonry" cols={3} gap={8}>
              {data?.service?.activityImages ? (
                data.service.activityImages.map((item, index) => (
                  <ImageListItem key={index}>
                    <img src={item.url} alt="" loading="lazy" />
                  </ImageListItem>
                ))
              ) : (
                <Loader />
              )}
            </ImageList>
          </Box>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}
