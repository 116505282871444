import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const lang =
  window.location.pathname.split("/")[1] || localStorage.getItem("lang");

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        home: "Home",
        services: "Services",
        projects: "Projects",
        addons: "Add-ons",
        "what’s new": "What’s New",
        "Get Quotation": "Get Quotation",
        "About our digital activition": "About our digital activition",
        "Success Partners": "Success Partners",
        "book now": "Book Now",
        "see it in action": "see it in action",
        "check it out": "check it out",
        videos: "Videos",
        gallery: "Gallery",
        "reserve now": "Reserve Now",
        activity: "Activity",
        "All right reserved to PentaValue© 2022":
          "All right reserved to PentaValue© 2022",
        "see all": "See All",
        "Get your Quotation!": "Get your Quotation!",
        Name: "Name",
        Industry: "Industry",
        Phone: "Phone",
        Email: "Email",
        "Select Service(s)": "Select Service(s)",
        Description: "Description",
        Submit: "Submit",
        our_services: "Our Services",
        media: "Media",
        products: "Products",
        price: "Price",
        modalContact:
          "Your interest in our services means the world to us. Kindly provide the necessary information below and our customer service team will be in touch with you at lightning speed!",
      },
    },
    ar: {
      translation: {
        home: "الصفحة الرئيسية",
        our_services: "خدماتنا",
        services: "خدمات",
        projects: "المشاريع",
        addons: "الإضافات",
        whatsnew: "ما الجديد",
        "Get Quotation": "احصل على الاقتباس",
        "About our digital activition": "عن نشاطنا الرقمي",
        "Success Partners": "شركاء النجاح",
        "book now": "احجز الآن",
        "see it in action": "رؤيته في العمل",
        "check it out": "تحقق من ذلك",
        "what’s new": "ما الجديد",
        videos: "فيديوهات",
        gallery: "صور",
        "reserve now": "احجز الان",
        activity: "نشاط",
        "All right reserved to PentaValue© 2022":
          "جميع الحقوق محفوظة لشركة PentaValue© 2022",
        "see all": "اظهار الكل",
        "Get your Quotation!": "احصل على عرض اسعارك!",
        Name: "اسم",
        Industry: "صناعة",
        Phone: "هاتف",
        Email: "بريد إلكتروني",
        "Select Service(s)": "اختر الخدمة (الخدمات)",
        Description: "وصف",
        Submit: "قدم",
        media: "إعلام",
        products: "منتجات",
        price: "السعر",
        modalContact:
          "اهتمامك بخدماتنا يعني العالم بالنسبة لنا. يرجى تقديم المعلومات اللازمة أدناه وسيتواصل معك فريق خدمة العملاء بسرعة البرق!",
      },
    },
  },
  fallbackLng: "en",
  lng: lang || "en",
  debug: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
