import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import MyAPI from "../../components/API";
import { useTranslation } from "react-i18next";
import YouTube from "react-youtube";

export default function Products() {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isLoadingVideos, setIsLoadingVideos] = useState(true);
  const [data, setData] = useState(null);
  const { t } = useTranslation();
  const { lang } = useParams();

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  function getYouTubeVideoId(url) {
    const videoIdPattern = /[?&]v=([^&]+)/;
    const match = url.match(videoIdPattern);

    if (match && match[1]) {
      return match[1];
    }

    return null;
  }

  return (
    <>
      <MyAPI endpoint={"products"} setData={setData} />

      {data ? (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "4em" }}>
          {data?.productCategories?.map((item, index) => (
            <Grid container spacing={2}>
              <Grid item xs={12} key={index} sx={{ maxWidth: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "2em",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: {
                        xs: "column",
                        sm: "row",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={item.image}
                        alt=""
                        style={{
                          maxHeight: "400px",
                          width: "100%",
                          display: imageLoaded ? "block" : "none",
                          objectFit: "cover",
                          objectPosition: "center",
                        }}
                        onLoad={handleImageLoad}
                      />
                      {!imageLoaded && <Loader />}
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                        bgcolor: "#211C52",
                        padding: "2em",
                      }}
                    >
                      <Typography
                        variant="h4"
                        sx={{
                          color: "#FFFFFF",
                          fontFamily: "CairoBold",
                          pointerEvents: "none",
                          direction: lang === "en" ? "ltr" : "rtl",
                        }}
                      >
                        {
                          item.translations.find(
                            (item) => item.fieldName === "title"
                          )?.translatedValue
                        }
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          color: "#FFFFFF",
                          textAlign: "center",
                          width: "70%",
                          pointerEvents: "none",
                          direction: lang === "en" ? "ltr" : "rtl",
                        }}
                      >
                        {
                          item.translations.find(
                            (item) => item.fieldName === "description"
                          )?.translatedValue
                        }
                      </Typography>
                    </Box>
                  </Box>
                  <Grid container spacing={2} sx={{ p: "1em" }}>
                    {item?.products?.map((product, index) => {
                      const isYouTubeVideo =
                        product?.url?.includes("youtube.com");

                      return (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          lg={3}
                          key={index}
                          sx={{ display: "flex" }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "1em",
                              justifyContent: "space-between",
                              outline: "1px solid rgba(220, 220, 220, 0.8)",
                              boxShadow: "0 0.5em 2em rgba(0, 0, 0, 0.2)",
                              borderRadius: "10px",
                              width: "100%",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "1em",
                              }}
                            >
                              <Typography
                                variant="h4"
                                sx={{
                                  color: "#211C52",
                                  fontFamily: "CairoBold",
                                  direction: lang === "en" ? "ltr" : "rtl",
                                  p: "0.5em 1em 0 1em",
                                  textAlign: "center",
                                }}
                              >
                                {product?.translations?.[0]?.translatedValue}
                              </Typography>
                              <img
                                src={product.image}
                                alt=""
                                style={{
                                  width: "100%",
                                  height: "200px",
                                  objectFit: "cover",
                                }}
                              />

                              {product?.url && isYouTubeVideo ? (
                                <YouTube
                                  videoId={getYouTubeVideoId(product.url)}
                                  opts={{
                                    width: "100%",
                                  }}
                                  style={{
                                    display: isLoadingVideos ? "none" : "block",
                                  }}
                                  onReady={() => setIsLoadingVideos(false)}
                                />
                              ) : (
                                <video
                                  src={product.url}
                                  controls
                                  style={{
                                    width: "100%",
                                    display: isLoadingVideos ? "none" : "block",
                                  }}
                                  onLoad={() => setIsLoadingVideos(false)}
                                />
                              )}
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "start",
                                direction: lang === "en" ? "ltr" : "rtl",
                                p: "1em",
                              }}
                            >
                              <Typography
                                variant="h6"
                                sx={{
                                  color: "#211C52",
                                  direction: lang === "en" ? "ltr" : "rtl",
                                  fontWeight: "700",
                                }}
                              >
                                {product?.translations?.[1]?.translatedValue}
                              </Typography>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "5px",
                                  direction: lang === "en" ? "ltr" : "rtl",
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  sx={{
                                    color: "#211C52",
                                    direction: lang === "en" ? "ltr" : "rtl",
                                    fontWeight: "600",
                                  }}
                                >
                                  {t("price")}:
                                </Typography>
                                <Typography
                                  variant="body1"
                                  sx={{
                                    color: "#211C52",
                                    direction: lang === "en" ? "ltr" : "rtl",
                                    fontWeight: "600",
                                  }}
                                >
                                  ${product.price}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          ))}
        </Box>
      ) : (
        <Loader />
      )}
    </>
  );
}
