import React, { useState, useEffect, useRef } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import Slider from "react-slick";
import { ArrowForwardIos } from "@mui/icons-material/";
import Loader from "../../../components/Loader";
import { useTranslation } from "react-i18next";

export default function Partners({ data }) {
  const sliderRef = useRef();
  const [sliderSettings, setSliderSettings] = useState({});
  const { t } = useTranslation();

  const previousSlide = () => {
    sliderRef.current.slickPrev();
  };

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  useEffect(() => {
    setSliderSettings({
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      swipe: true,
      swipeToSlide: true,
      arrows: false,
      autoplay: true,
      rows: 2,
    });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      let slidesToShow = 5;

      if (window.innerWidth < 960) {
        slidesToShow = 3;
      }

      if (window.innerWidth < 600) {
        slidesToShow = 1;
      }

      setSliderSettings((prevSettings) => ({
        ...prevSettings,
        slidesToShow,
      }));
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "100%",
        gap: "2em",
        padding: "3em",
      }}
    >
      <Typography
        variant="h3"
        sx={{ margin: "0 auto", fontFamily: "CairoBold" }}
      >
        {t("Success Partners")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <IconButton onClick={previousSlide}>
          <ArrowForwardIos
            sx={{ transform: "rotate(180deg)", color: "#636363" }}
          />
        </IconButton>
        <Box sx={{ display: "block", maxWidth: "90%", flex: 1 }}>
          {data ? (
            <Slider ref={sliderRef} {...sliderSettings}>
              {data?.map((partner, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    m: { xs: "1em 0", md: "2em 0", xl: "3em 0" },
                  }}
                >
                  <img
                    src={partner.image}
                    alt={`partner ${index}`}
                    style={{
                      height: "50px",
                      maxWidth: "150px",
                      margin: "0 auto",
                    }}
                  />
                </Box>
              ))}
            </Slider>
          ) : (
            <Loader />
          )}
        </Box>
        <IconButton onClick={nextSlide}>
          <ArrowForwardIos sx={{ color: "#636363" }} />
        </IconButton>
      </Box>
    </Box>
  );
}
