import React, { useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import MyAPI from "../../components/API";
import { useTranslation } from "react-i18next";

export default function ServicesPage() {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [data, setData] = useState(null);
  const { t } = useTranslation();
  const { lang } = useParams();

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <>
      <MyAPI endpoint={"services"} setData={setData} />
      {data ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "2em",
            pointerEvents: "none",
            gap: lang === "en" ? "1em" : "2em",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              color: "#211C52",
              fontFamily: "CairoBold",
              fontSize: { xs: "52px", sm: "56px", md: "62px", lg: "72px" },
              direction: lang === "en" ? "ltr" : "rtl",
            }}
          >
            {data?.staticPages?.map(
              (item) => item.translations[0].translatedValue
            )}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: "#211C52",
              width: { xs: "100%", md: "70%" },
              fontSize: "20px",
              direction: lang === "en" ? "ltr" : "rtl",
            }}
          >
            {data?.staticPages?.map(
              (item) => item.translations[1].translatedValue
            )}
          </Typography>
        </Box>
      ) : (
        <Loader />
      )}
      {data ? (
        <Grid container spacing={8} sx={{ pb: "4em" }}>
          {data?.services?.map((item, index) => (
            <Grid item xs={12} key={index} sx={{ maxWidth: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "column",
                    sm: [index % 2 === 0 ? "row" : "row-reverse"],
                  },
                  flexWrap: "wrap",
                }}
              >
                <Box
                  sx={{ flex: 1, display: "flex", justifyContent: "center" }}
                >
                  <img
                    src={item.image}
                    alt=""
                    style={{
                      maxHeight: "400px",
                      width: "100%",
                      display: imageLoaded ? "block" : "none",
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                    onLoad={handleImageLoad}
                  />
                  {!imageLoaded && <Loader />}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1em",
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    bgcolor: "#211C52",
                    padding: "2em",
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      color: "#FFFFFF",
                      fontFamily: "CairoBold",
                      pointerEvents: "none",
                      direction: lang === "en" ? "ltr" : "rtl",
                    }}
                  >
                    {
                      item.translations.find(
                        (item) => item.fieldName === "title"
                      )?.translatedValue
                    }
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "#FFFFFF",
                      textAlign: "center",
                      width: "70%",
                      pointerEvents: "none",
                      direction: lang === "en" ? "ltr" : "rtl",
                    }}
                  >
                    {
                      item.translations.find(
                        (item) => item.fieldName === "description"
                      )?.translatedValue
                    }
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "1em",
                      "> :not(Button) .MuiTypography-root": {
                        fontFamily: "CairoMedium",
                      },
                    }}
                  >
                    <Button
                      component={Link}
                      to={`/${lang}/services/${item.id}`}
                      sx={{
                        background:
                          "linear-gradient(to right, #CD51CA, #5360E2, #3065E9)",
                        color: "white",
                        direction: lang === "en" ? "ltr" : "rtl",
                      }}
                    >
                      <Typography>{t("book now")}</Typography>
                    </Button>
                    <Button
                      sx={{
                        background:
                          "linear-gradient(to right, #CDDDFE, #F0CEF3)",
                        direction: lang === "en" ? "ltr" : "rtl",
                      }}
                    >
                      <Typography
                        sx={{
                          background:
                            "linear-gradient(to right, #D154CE, #4162D5)",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                        }}
                      >
                        <Typography>{t("see it in action")}</Typography>
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Loader />
      )}
    </>
  );
}
