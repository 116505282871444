import { Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import MyAPI from "../../components/API";
import Loader from "./../../components/Loader";
import { useParams } from "react-router-dom";

export default function Projects() {
  const [data, setData] = useState(null);
  const { lang } = useParams();

  return (
    <>
      <MyAPI endpoint={"projects"} setData={setData} />
      <Box sx={{ padding: { xs: "1em", md: "2em", lg: "4em" } }}>
        {data ? (
          <Grid container spacing={6}>
            {data?.projects?.map((project, index) => (
              <Grid item xs={12} md={6} sx={{ display: "flex" }}>
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    gap: "1em",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      color: "#211C52",
                      fontFamily: "CairoBold",
                      fontSize: { xs: "24px", md: "36px", xl: "48px" },
                      direction: lang === "en" ? "ltr" : "rtl",
                    }}
                  >
                    {project.translations.map((item) => item.translatedValue)}
                  </Typography>
                  <Box
                    sx={{
                      position: "relative",
                      borderRadius: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      overflow: "hidden",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: -1,
                        backgroundImage: `url(${project.image})`,
                        backgroundSize: "cover",
                        filter: "blur(10px)",
                        borderRadius: "15px",
                      }}
                    />
                    <img
                      src={project.image}
                      alt=""
                      style={{
                        height: "400px",
                        objectFit: "contain",
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Loader />
        )}
      </Box>
    </>
  );
}
